import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import bannerImg from '../../assets/images/about/about-img10.png'

const ApplyBanner = () => {
    return (
        <div className="it-services-banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="main-banner-content">
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>Let's Work Together</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>Passionate about emerging tech?</p>
                                <p>We are always open for talented people!</p>
                                <p>Send your CV and free-form motivation letter to <a href="mailto:info@noroutine.com">info@noroutine.com</a> </p>
                            </ReactWOW>

                        </div>
                    </div>

                    <div className="offset-lg-1 col-lg-5 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="main-banner-image">
                                <img src={bannerImg} alt="banner" />
                            </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplyBanner