import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import ApplyBanner from "../components/AboutUs/ApplyBanner"
import Footer from "../components/App/Footer"

const Apply = () => {
    return (
        <Layout>
            <Navbar />
            <ApplyBanner />
            <Footer />
        </Layout>
    );
}

export default Apply